import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import assets
import BrandStackedLogo from "../../../assets/images/brand-logo/tenxgeeks-stacked-footer-logo.svg"
import FacebookLogo from "../../../assets/images/icons/tenxgeeks-facebook-icon.svg"
import LinkedinLogo from "../../../assets/images/icons/tenxgeeks-linkedin-icon.svg"
import TwitterLogo from "../../../assets/images/icons/tenxgeeks-twitter-icon.svg"
import InstaLogo from "../../../assets/images/icons/tenxgeeks-insta-icon.svg"
import BehanceIcon from "../../../assets/images/icons/tenxgeeks-behance-icon.svg"
import DribbbleIcon from "../../../assets/images/icons/tenxgeeks-dribbble-icon.svg"

// Styled components for Footer
const Footer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${systemCss({
    bg: "dark"
  })}
`

const FooterWrapper = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 7rem 7rem;

  ${xl} {
    padding: 7rem 4rem;
  }

  ${lg} {
    padding: 4rem 4rem;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0px -3.5rem;

  & > * {
    padding: 0 3.5rem;
    /* padding-bottom: 7rem; */
  }

  ${xl} {
    margin: 0px -3rem;
    & > * {
      padding: 0 3rem;
    }
  }

  ${lg} {
    margin-bottom: -4rem;
    & > * {
      padding-bottom: 4rem;
    }
  }

  ${md} {
    margin: 0px -2rem -4rem;
    & > * {
      padding: 0 2rem 4rem;
    }
  }
`

const BrandSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${lg} {
    flex-grow: 1;
    flex-basis: 100%;
    align-items: center;
  }

  ${sm} {
    padding-bottom: 6rem;
  }
`

const BrandLogo = styled.img`
  height: 16rem;
  width: auto;

  ${xl} {
    height: 13rem;
  }
`

const FooterNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${sm} {
    flex-grow: 1;
    flex-basis: 50%;
  }
`

const FooterNavHeading = styled.h5`
  font-weight: 700;
  padding: 0px;
  margin: 0px;

  ${systemCss({
    fontSize: { _: "h5", lg: "link" },
    color: "white"
  })}

  /* ${lg} {
    ${systemCss({
      fontSize: "link"
    })}
  } */
`

const FooterNavList = styled.ul`
  margin-top: 2rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${lg} {
    margin-top: 1.5rem;
  }
`

const FooterNavLink = styled.li`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  & > a {
    ${systemCss({
      fontSize: "link",
      color: "yellow"
    })}
    cursor: pointer;
    text-decoration: none;

    &:hover {
      ${systemCss({
        color: "yellow"
      })}
    }
  }

  ${lg} {
    &:not(:first-of-type) {
      margin-top: 1.25rem;
    }

    & > a {
      ${systemCss({
        fontSize: "1"
      })}
    }
  }
`

const SocialMediaPlugs = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > a {
    text-decoration: none;
    padding: 0.25rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: #fff;
    }

    & > img {
      display: block;
      height: 1.8rem;
      width: auto;
    }
  }
`

const Copyright = styled.span`
  display: block;
  margin-top: 2rem;
  font-weight: 400;

  ${systemCss({
    color: "yellow",
    fontSize: "link"
  })}

  ${lg} {
    display: none;
  }
`

const CopyrightSmall = styled.span`
  display: none;
  text-align: center;
  margin-top: 4rem;
  font-weight: 400;

  ${systemCss({
    fontSize: "1",
    color: "yellow"
  })}

  ${lg} {
    display: block;
  }
`

export default props => {
  return (
    <Footer>
      <FooterWrapper>
        <FooterContainer>
          <BrandSection>
            <BrandLogo src={BrandStackedLogo} alt="tenxgeeks logo" />
            <Copyright>&copy; tenxgeeks 2020</Copyright>
          </BrandSection>

          {/* Services section */}
          <FooterNav>
            <FooterNavHeading>Services</FooterNavHeading>
            <FooterNavList>
              <FooterNavLink>
                <Link to="/services">Design Sprints</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="/services">Ui/UX Design</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="/services">Branding</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="/services">Web Development</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="/services">Mobile Development</Link>
              </FooterNavLink>
            </FooterNavList>
          </FooterNav>

          {/* Company section */}
          <FooterNav>
            <FooterNavHeading>Company</FooterNavHeading>
            <FooterNavList>
              <FooterNavLink>
                <Link to="#">Our Story</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="#">Partner Program</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="/about-us">About Us</Link>
              </FooterNavLink>
              <FooterNavLink>
                <Link to="#">Careers</Link>
              </FooterNavLink>
            </FooterNavList>
          </FooterNav>

          {/* Contact Us section */}
          <FooterNav>
            <FooterNavHeading>Contact Us</FooterNavHeading>
            <FooterNavList>
              <FooterNavLink>
                <a href="tel:+91-8168238248">+91-8168238248</a>
              </FooterNavLink>
              <FooterNavLink>
                <a href="mailto:support@tenxgeeks.com">Support@tenxgeeks.com</a>
              </FooterNavLink>
            </FooterNavList>
            <SocialMediaPlugs>
              <a
                href="https://facebook.com/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookLogo} alt="facebook plug" />
              </a>
              <a
                href="https://twitter.com/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterLogo} alt="twitter plug" />
              </a>
              <a
                href="https:/linkedin.com/company/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedinLogo} alt="linkedin plug" />
              </a>
              <a
                href="https://www.instagram.com/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstaLogo} alt="instagram plug" />
              </a>
              <a
                href="https://www.behance.net/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={BehanceIcon} alt="behance plug" />
              </a>
              <a
                href="https://dribbble.com/tenxgeeks"
                target="_blank"
                rel="noreferrer"
              >
                <img src={DribbbleIcon} alt="dribbble plug" />
              </a>
            </SocialMediaPlugs>
          </FooterNav>
        </FooterContainer>
        <CopyrightSmall>&copy; tenxgeeks 2020</CopyrightSmall>
      </FooterWrapper>
    </Footer>
  )
}
