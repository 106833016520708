import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"
import { navigate } from "gatsby"

import { sm, md, xl } from "../../../utils/mediaQueries"

// Import assets
import PrimaryLogo from "../../../assets/images/brand-logo/tenxgeeks-primary-logo.svg"
import Hamburger from "../../../assets/images/icons/hamburger.svg"
import HamburgerClose from "../../../assets/images/icons/hamburger-close.svg"

// Styled components for Header

const Header = styled.div`
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  margin-top: 2rem;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #ffffff;
  box-shadow: ${props =>
    props.isFixed
      ? "5px 5px 10px rgba(0, 0, 0, 0.1)"
      : props.isOpen
      ? "5px 5px 10px rgba(0, 0, 0, 0.1)"
      : "none"};

  transition: top 0.2s ease-in-out;
`

const HeaderContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 7rem;

  ${xl} {
    padding: 2rem 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  ${md} {
    padding: 2rem 2.5rem;
  }
`

const BrandContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${xl} {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const BrandLogoLink = styled(Link)`
  display: block;
  height: 4rem;

  ${md} {
    height: 2.5rem;
  }
`

const BrandLogo = styled.img`
  display: block;
  height: 100%;
  width: auto;
`

const HamburgerButton = styled.button`
  display: none;
  height: 4rem;
  width: 4rem;
  padding: 0.25rem;
  background: #fff;
  cursor: pointer;
  border: none;
  outline: none !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  ${xl} {
    display: flex;
    outline: none !important;
  }

  ${md} {
    height: 3rem;
    width: 3rem;
  }
`

const NavigationList = styled.ul`
  margin: 0px 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  list-style: none;
  margin-left: auto;

  transition: max-height 0.3s ease-in-out, margin 0.5s;

  ${xl} {
    margin: ${props => (props.isOpen ? "0px" : "0px !important")};
    max-height: ${props => (props.isOpen ? "30rem" : "0px")};
    margin-top: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
  }
`

const NavigationLink = styled.li`
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:not(:first-of-type) {
    margin-left: 3.5rem;
  }

  &:last-of-type {
    display: none;
  }

  & > a {
    text-decoration: none;
    padding: 0.25rem 0.25rem;
    font-weight: 500;

    ${systemCss({
      fontSize: "h5",
      color: "dark",
      fontFamily: "primary"
    })}

    &::after {
      transition: all 0.3s ease-in-out;
      content: "";
      position: absolute;
      bottom: -0.4rem;
      left: 0;
      height: 0.6rem;
      width: 0px;
      border-radius: 0.4rem;
      ${systemCss({
        bg: "purple"
      })}
    }
  }

  &:hover {
    & > a {
      ${systemCss({
        color: "purple"
      })}

      &::after {
        width: 3.6rem;
      }
    }
  }

  ${xl} {
    margin: 1.6rem 0px;
    width: 100%;
    justify-content: flex-start;

    &:not(:first-of-type) {
      margin: 1.6rem 0px;
    }

    &:last-of-type {
      display: flex;
      margin: 1.6rem 0px 0.5rem;

      & > a {
        ${systemCss({
          color: "yellow"
        })}
      }
    }

    & > a {
      font-weight: 600;
      ${systemCss({
        fontSize: "link"
      })}
    }
  }
`

const ActiveNavigationLink = {
  color: "#5d1398"
}

const CTAButton = styled.button`
  display: block;
  padding: 1.2rem 1.8rem;
  font-weight: 500;
  border-radius: 8rem;
  cursor: pointer;
  border: none;

  ${systemCss({
    fontSize: "h5",
    color: "white",
    fontFamily: "primary",
    bg: "purple"
  })}

  ${xl} {
    display: none;
  }
`

const hamburgerClose = css`
  display: none;
`

const hamburgerOpen = css`
  display: flex;
`

export default props => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [isFixedHeader, setFixedHeader] = useState(false)

  const toggleHamburger = () => {
    setNavOpen(!isNavOpen)
  }

  const handleStickyHeader = () => {
    const scrollOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scrollOffset > 10) {
      // Use fixed header
      if (!isFixedHeader) setFixedHeader(true)
    } else if (scrollOffset <= 10) {
      // Use sticky header
      setFixedHeader(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleStickyHeader)

    return () => {
      window.removeEventListener("scroll", handleStickyHeader)
    }
  }, [])

  return (
    <>
      <Header isFixed={isFixedHeader} isOpen={isNavOpen}>
        <HeaderContainer>
          <BrandContainer>
            <BrandLogoLink to="/">
              <BrandLogo src={PrimaryLogo} alt="tenxgeeks logo" />
            </BrandLogoLink>
            <HamburgerButton onClick={toggleHamburger}>
              <img
                src={Hamburger}
                alt="navbar-hamburger"
                css={isNavOpen ? hamburgerClose : hamburgerOpen}
              ></img>
              <img
                src={HamburgerClose}
                alt="navbar-hamburger"
                css={isNavOpen ? hamburgerOpen : hamburgerClose}
              ></img>
            </HamburgerButton>
          </BrandContainer>

          <NavigationList isOpen={isNavOpen}>
            <NavigationLink>
              <Link to="/about-us" activeStyle={ActiveNavigationLink}>
                About Us
              </Link>
            </NavigationLink>
            <NavigationLink>
              <Link to="/services" activeStyle={ActiveNavigationLink}>
                Services
              </Link>
            </NavigationLink>
            <NavigationLink>
              <Link to="/contact-us">Start a Project</Link>
            </NavigationLink>
          </NavigationList>

          <CTAButton
            type="button"
            onClick={() => {
              navigate("/contact-us")
            }}
          >
            Start a Project
          </CTAButton>
        </HeaderContainer>
      </Header>
    </>
  )
}
