import React from "react"
import { Global, css } from "@emotion/core"
import { normalize } from "polished"

// import assets
import SpartanFonts from "../../assets/fonts/Spartan/Spartan-fonts.css"
import GilroyFonts from "../../assets/fonts/Gilroy/Gilroy-fonts.css"

// Import theme
import theme from "../../theme"

const GlobalStyles = ({ children }) => {
  return (
    <Global
      styles={css`
        /* Normalizer css or css Reset */
        ${normalize()}

        /* Import offline first fonts */
        /* Font : Spartant, Source : Google fonts */
        /* @import url(SpartanFonts); */

        /* Font : Gillroy, Source : Open Source fonts */
        @import url(GilroyFonts);

        * {
          box-sizing: border-box;
          padding: 0px;
          margin: 0px;
        }

        *,
        :after,
        :before {
          box-sizing: border-box;
        }

        html {
          line-height: 1.15;
          font-size: 62.5%;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          -ms-overflow-style: scrollbar;
          scroll-behavior: smooth;
        }

        body {
          font-family: "Gilroy", sans-serif;
          font-weight: 400;
          line-height: 1.4;
          font-size: 1.8rem;
          margin: 0;
          text-align: left;
          color: #000;
          background-color: #fff;
          position: relative;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: inherit;
          line-height: 1.1;
          margin: 0px;
          color: inherit;
        }

        h1 {
          font-size: ${theme.fontSizes.h1};
        }

        h2 {
          font-size: ${theme.fontSizes.h2};
        }

        h3 {
          font-size: ${theme.fontSizes.h3};
        }

        h4 {
          font-size: ${theme.fontSizes.h4};
        }

        h5 {
          font-size: ${theme.fontSizes.h5};
        }

        button {
          outline: none !important;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
        }

        ul {
          list-style: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        a {
          text-decoration: none;
          outline: none !important;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
        }
      `}
    />
  )
}

export default GlobalStyles
